import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Badge } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Toast",
  "componentId": "toast",
  "description": "Toasts display a temporary status message to the user in the bottom right corner of the page",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Documentation in progress-----------------------`}</strong></p>
    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <h4>{`Warning Status`}</h4>
    <p>{`We detect there is a potential issue with a user’s input choice`}</p>
    <ul>
      <li parentName="ul">{`Suggested Design Solution: Inline messaging`}</li>
      <li parentName="ul">{`A process that the user initiated (e.g. data import) has errors.`}</li>
    </ul>
    <h4>{`Success Status`}</h4>
    <ul>
      <li parentName="ul">{`User initiated process completes (data upload)`}</li>
      <li parentName="ul">{`Automatic process is successful (auto-save)`}</li>
      <li parentName="ul">{`Manual process is successful (form filled out)`}</li>
    </ul>
    <h4>{`Informational Status`}</h4>
    <ul>
      <li parentName="ul">{`System is processing an action (upload, save, etc.)`}</li>
    </ul>
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      